import React, {useState} from 'react';
import { Link } from 'react-router-dom'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import TodayPicks from '../components/layouts/explore-01/TodayPicks'
import todayPickData from '../assets/fake-data/data-today-pick';
import { POSClient, use } from "@maticnetwork/maticjs";
import { Web3ClientPlugin } from "@maticnetwork/maticjs-ethers";
import { ethers } from 'ethers'
import { walletAddress } from '../recoil/state';
import { useRecoilState } from 'recoil'

use(Web3ClientPlugin);

const Explore01 = () => {

    const [account, setAccount] = useRecoilState(walletAddress)
    const handleWallet = async()=> {
        // console.log("Hola Wallet")
        if (!window.ethereum) {
            return alert("Metamask not installed or not enabled");
        }
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const account = await signer.getAddress();
        // console.log("Account:", account);
        setAccount(account)
    }

    return (
        <div className='home-7'>
            <Header handleWallet={handleWallet} account={account}/>
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">All NFT's</h1>
                            </div>
                            {/* <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Explore</Link></li>
                                    <li>All NFT's</li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>                    
            </section>
            <TodayPicks data={todayPickData} />
            <Footer />
        </div>
    );
}


export default Explore01;
