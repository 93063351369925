import React, {useState}  from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Slider from '../components/slider/Slider';
import TodayPicks from '../components/layouts/todayPicks/TodayPicks';
import { walletAddress } from '../recoil/state';
import { useRecoilState } from 'recoil'
import { POSClient, use } from "@maticnetwork/maticjs";
import { Web3ClientPlugin } from "@maticnetwork/maticjs-ethers";
import { ethers } from 'ethers'

use(Web3ClientPlugin);
// const maticRPCTweestTest = "https://rpc-mumbai.maticvigil.com/v1/bf8fe120c59a1defd40695662ef3ff207c8a0ab5"
// const posClient = new POSClient();
// const contractAddress = "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0"

const Home07 = () => {
    const [account, setAccount] = useRecoilState(walletAddress)

    const handleWallet = async()=> {
        // console.log("Hola Wallet")
        if (!window.ethereum) {
            return alert("Metamask not installed or not enabled");
        }
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const account = await signer.getAddress();
        // console.log("Account:", account);
        setAccount(account)
    }
    return (
        <div className='home-7'>
            <Header handleWallet={handleWallet} account={account}/>
            <Slider/>
            {/* <Category /> */}
            <TodayPicks />
            <Footer />
        </div>
    );
}

export default Home07;
