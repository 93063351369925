import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import {
  RecoilRoot
} from 'recoil';

ReactDOM.render(
  <RecoilRoot>
    <BrowserRouter >
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </RecoilRoot>,
  document.getElementById('root')
);
