import React from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import { POSClient, use } from "@maticnetwork/maticjs";
import { Web3ClientPlugin } from "@maticnetwork/maticjs-ethers";
import { ethers } from 'ethers'
import { useRecoilState } from 'recoil'
import { walletAddress } from '../../recoil/state';

use(Web3ClientPlugin);

const CardModal = (props) => {

    // console.log(props)
    const [account, setAccount] = useRecoilState(walletAddress)


    const handleTransaction = async()=> {
        if (!window.ethereum) {
            return alert("Metamask not installed or not enabled");
        }
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const account = await signer.getAddress();
        // console.log("Account:", account);
        setAccount(account)
        props.onHide()
    }
    
return (

    <Modal
    show={props.show}
    onHide={props.onHide}
  >
    <Modal.Header closeButton></Modal.Header>

    <div className="modal-body space-y-20 pd-40">
        <h3>Buy NFT</h3>
        {/* <p className="text-center">You must bid at least <span className="price color-popup">4.89 ETH</span>
        </p>
        <input type="text" className="form-control"
            placeholder="00.00 ETH" />
        <p>Enter quantity. <span className="color-popup">5 available</span>
        </p>
        <input type="number" className="form-control" placeholder="1" />
        <div className="hr"></div> */}
        <div className="d-flex justify-content-between">
            <p> NFT:</p>
            <p className="text-right price color-popup"> 100 MATIC </p>
        </div>
        <div className="d-flex justify-content-between">
            <p> Service free:</p>
            <p className="text-right price color-popup"> 1 MATIC </p>
        </div>
        <div className="d-flex justify-content-between">
            <p> Total amount:</p>
            <p className="text-right price color-popup"> 101 MATIC </p>
        </div>
        <button onClick={handleTransaction} className="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Close"> Purchase</button>
    </div>
    </Modal>
    
  );
};

export default CardModal;
